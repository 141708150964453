/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZUREAD_CLIENT_ID,
    authority: `${process.env.REACT_APP_AZUREAD_INSTANCE}${process.env.REACT_APP_AZUREAD_TENANT_ID}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
}

export const loginRequest = {
  scopes: [`https://graph.microsoft.com/User.Read`],
  prompt: 'select_account'
}

export const tokenRequest = {
  scopes: [`api://${process.env.REACT_APP_AZUREAD_CLIENT_ID}/access_as_user`],
}
