import React from 'react'
import { FunctionFactory, Model } from 'survey-core'
import 'survey-core/survey.i18n.js'
import showdown from 'showdown'
import { Survey } from 'survey-react-ui'
import 'survey-core/defaultV2.min.css'
import './survey-overrides.css'
import { surveyJson } from './surveyJson'
import axios from 'axios'
import { useMsal } from '@azure/msal-react'
import { tokenRequest } from './authConfig'

function getLocalizedDistanceUnit(unit, locale) {
  if (locale === '') locale = 'default'

  const distanceUnits = [
    {
      id: 'km',
      title: {
        default: 'kilometers',
        es: 'kilómetros',
        fr: 'kilomètres',
        'pt-BR': 'quilômetros',
      },
    },
    {
      id: 'miles',
      title: {
        default: 'miles',
        es: 'millas',
        fr: 'miles',
        'pt-BR': 'milhas',
      },
    },
  ]

  const selectedUnit = distanceUnits.filter((item) => item.id === unit)[0]
  const selectedLocalizedUnit = selectedUnit.title[locale]

  return selectedLocalizedUnit
}

/* To determine the unit for conversion during post-processing of data. */
function setDistanceUnit(params) {
  const survey = this.survey
  const country = params[0]
  const field = params[1]
  const distanceUnit = country === 'united-kingdom' || country === 'united-states' ? 'miles' : 'km'

  survey.setValue(field, distanceUnit)
}

/* To determine the unit to show in the localized UI. */
function setLocalizedDistanceUnit(params) {
  const survey = this.survey
  const country = params[0]
  const field = params[1]
  const locale = survey.locale
  const distanceUnit =
    country === 'united-kingdom' || country === 'united-states'
      ? getLocalizedDistanceUnit('miles', locale)
      : getLocalizedDistanceUnit('km', locale)

  survey.setValue(field, distanceUnit)
}

FunctionFactory.Instance.register('setDistanceUnit', setDistanceUnit)
FunctionFactory.Instance.register('setLocalizedDistanceUnit', setLocalizedDistanceUnit)

function SurveyComponent() {
  const { instance, accounts } = useMsal()
  const survey = new Model(surveyJson)

  // Retrieve optional locale in query string, i.e.: `/?locale=fr`
  if (window.location.search) {
    if (window.location.search.split('=')[0].replace('?', '') !== 'locale') return
    survey.locale = window.location.search.split('=')[1]
  }

  survey.onComplete.add((sender, _options) => {
    // Silently acquire an access token and attach it to a the survey response data post request.
    instance
      .acquireTokenSilent({
        ...tokenRequest,
        account: accounts[0],
      })
      .then((response) => {
        axios.post(`${process.env.REACT_APP_API_BASE_URL}/survey-responses`, sender.data, {
          headers: {
            Authorization: `Bearer ${response.accessToken}`,
          },
        })
      })
  })

  // Instantiate Showdown in order to enable MarkDown in content
  const converter = new showdown.Converter()
  survey.onTextMarkdown.add(function (_survey, options) {
    // Convert Markdown to HTML
    let htmlString = converter.makeHtml(options.text)
    // Get closing tag
    const closingString = htmlString.substring(htmlString.length - 4, htmlString.length)
    // Check if closing tag is a paragraph
    if (closingString === '</p>') {
      // Remove root paragraphs <p></p>
      htmlString = htmlString.substring(3)
      htmlString = htmlString.substring(0, htmlString.length - 4)
    }
    // Set HTML markup to render
    options.html = htmlString
  })

  return <Survey model={survey} />
}

export default SurveyComponent
