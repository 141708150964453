function LogoComponent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 787.7 161.3">
      <path
        fill="currentColor"
        d="M74.8 158.2H42.2L0 41h34.5l24.3 86.4h.4l25-86.4h33L74.8 158.2zm145.1-22.9c0 13.9 3.1 17 6.9 18.3v4.6h-33.4c-1.8-5.5-2.2-7.3-2.9-13.9-7.1 7.3-17 17-38.1 17-17.7 0-35.8-9.9-35.8-34.1 0-22.9 14.6-34.6 34.3-37.4l27.9-4.2c4.9-.7 10.8-2.7 10.8-9.3 0-13-12.4-13.7-20.3-13.7-15.5 0-18.1 9.5-19 16.3h-29.9c3.5-35.9 28.5-41 52.7-41 16.6 0 46.9 5.1 46.9 33.9v63.5zm-30.3-34.2c-3.1 2.4-8.2 4.4-21 6.4-11.5 2-21 4.9-21 17.6 0 10.8 8.4 14.1 14.4 14.1 13.7 0 27.6-8.8 27.6-23.1v-15zm79.9 57.1h-31V0h31v158.2zM328.4 41h19.2v22h-19.2v63.7c0 6.8.2 9.5 12.8 9.5 2 0 4.2-.2 6.4-.4V159c-4.9.2-9.7.5-14.6.5h-4c-26.8 0-31.6-10.1-31.6-25.4V63h-15.9V41h15.9V9.2h31V41zm140 82.8c-8.2 28.2-31.6 37.5-51.8 37.5-33.2 0-58.6-15.9-58.6-64.1 0-14.1 4.9-59.3 56.4-59.3 23.2 0 55.3 11 55.3 64.5v5.5H389c.9 8.8 2.7 28.6 27.7 28.6 8.6 0 17.5-4.4 19.9-12.8h31.8zm-30.5-35.9c-1.8-19-13.9-24.7-23.9-24.7-14.6 0-22.3 9.3-24.1 24.7h48zm148.2 27.5c-1.1 12.1-10.6 45.8-53.8 45.8-44.3 0-54.4-33.7-54.4-58.4 0-37.9 16.6-65 56.4-65 18.6 0 49.1 8.4 52 44.9h-31.4c-1.3-7.5-5.8-18.9-19.1-18.9h-1.5c-19.5 0-24.6 19.4-24.6 33.9 0 12.8.4 38.1 23.9 38.1 15.7 0 21-15 21-20.5h31.5zm120.4 42.8h-31V87c0-8.6-.4-23.1-20.1-23.1-13.7 0-24.3 9.3-24.3 27.1v67.2h-31V0h31v56.2h.4c4-9.7 17.2-18.3 34.1-18.3 23 0 40.9 13.4 40.9 39.2v81.1zm19.2-22.1h62v22h-62z"
      />
    </svg>
  )
}

export default LogoComponent
