import LogoComponent from './logo'
import SurveyComponent from './SurveyComponent'
import './App.css'
import './index.css'
import React from 'react'
import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import { loginRequest } from './authConfig'

function ErrorComponent({ error }) {
  return <p>An Error Occurred: {error}</p>
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>
}

function App() {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}
    >
      <div className="App">
        <header className="App-header">
          <div className="App-logo">
            <LogoComponent />
          </div>
        </header>
        <SurveyComponent />
      </div>
    </MsalAuthenticationTemplate>
  )
}

export default App
